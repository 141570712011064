<!-- eslint-disable max-len -->
<template>
    <div class="study">
        <Anchor :id="'study'" />
        <div class="block">
            <div class="block-title">
                <div class="dot" />
                {{ $t('study.landing.title[0]') }}
            </div>
            <div class="block-content">
                {{ $t('study.landing.content[0]') }}
            </div>
        </div>
        <Anchor :id="'SROI'" />
        <div class="block">
            <div class="block-title">
                <div class="dot" />
                {{ $t('study.landing.title[1]') }}
            </div>
            <div class="list-group">
                <v-progress-circular
                    v-if="!showData"
                    indeterminate
                    color="orange"
                />
                <div
                    v-if="showData"
                    class="list-item"
                    @click="goToSRIO()"
                >
                    <img
                        class="list-img"
                        width="280px"
                        height="210px"
                        :src="image(SROIdata.image)"
                        alt=""
                    >
                    <div class="list-content">
                        <div class="list-title">
                            {{ SROIdata.title }}
                        </div>
                        <div class="list-caption">
                            {{ SROIdata.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Anchor :id="'FUN'" />
        <div class="block">
            <div class="block-title">
                <div class="dot" />
                {{ $t('study.landing.title[2]') }}
            </div>
            <div class="block-content">
                {{ $t('study.landing.content[1]') }}
            </div>
            <ThreeBlock
                :caption-open="false"
                :block-data="funLecture"
                :router-name="'StudyFUNItem'"
                :more-router-link="'StudyFUN'"
                :show-data="showData"
            />
        </div>
        <Anchor :id="'history'" />
        <div class="block mb-16">
            <div class="block-title">
                <div class="dot" />
                {{ $t('study.landing.title[3]') }}
            </div>
            <div class="block-content mb-11">
                {{ $t('study.landing.content[2]') }}
            </div>
            <div class="panels">
                <v-expansion-panels
                    multiple
                    accordion
                >
                    <v-expansion-panel
                        v-for="(history, index) in historyLecture"
                        :key="index"
                        class="panel-item"
                    >
                        <v-expansion-panel-header class="history-title">
                            {{ history.title }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            :eager="true"
                        >
                            <div class="history-content">
                                <div
                                    v-for="(item, i) in history.items"
                                    :key="i"
                                    class="history-item"
                                    @click="goToLink(item)"
                                >
                                    <v-img
                                        width="280px"
                                        height="280px"
                                        :src="image(item.image)"
                                    />

                                    <div
                                        class="history-title mt-2"
                                    >
                                        {{ item.title }}
                                    </div>
                                </div>
                            </div>
                            <div
                                class="swiper swiper-history"
                            >
                                <div
                                    class="swiper-wrapper"
                                >
                                    <div
                                        v-for="(item, i) in history.items"
                                        :key="i"
                                        class="swiper-slide"
                                    >
                                        <div
                                            class="content"
                                            @click="goToLink(item)"
                                        >
                                            <v-img
                                                :src="image(item.image)"
                                                class="swiper-img"
                                            />
                                            <div class="history-title mt-2">
                                                {{ item.title }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-button-prev invention-swiper-button-prev" />
                                <div class="swiper-button-next invention-swiper-button-next" />
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </div>
    </div>
</template>

<script>
import API from '@/api';
import { mapMutations } from 'vuex';
import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import ThreeBlock from '@/components/list/ThreeBlock.vue';
import Nzh from 'nzh';
import 'nzh/hk';

export default {
    components: {
        ThreeBlock,
    },
    data() {
        return {
            swiper: null,
            showData: false,
            SROIdata: null,
            funLecture: {
                type: 'router',
                items: null,
            },
            historyLecture: [],
        };
    },
    computed: {},
    watch: {
        $route() {
            this.init();
        },
    },
    created() {
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        initSwiper() {
            const slidesPerView = 1;
            // eslint-disable-next-line no-new
            this.swiper = new Swiper('.swiper-history', {
                modules: [Navigation],
                loop: true,
                observer: true,
                observeParents: true,
                slidesPerView,
                spaceBetween: 0,
                navigation: {
                    nextEl: '.invention-swiper-button-next',
                    prevEl: '.invention-swiper-button-prev',
                },
                autoplay: false,
            });
        },
        async init() {
            try {
                const res = await API.BookSori.PublicFetchResource('000000000000000000000000', {
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.SROIdata = res.data;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            try {
                const res = await API.BookReport.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                this.funLecture.items = res.data.raw;
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            try {
                const historyLecture = [];
                const res = await API.BookHistory.PublicList({
                    headers: {
                        'Content-Language': (this.$route.path.split('/')[1] || 'zhTw') === 'en' ? 'en' : 'zhTw',
                    },
                });
                const session = [];
                for (let i = 0; i < res.data.raw.length; i += 1) {
                    if (!session.includes(res.data.raw[i].session)) {
                        session.push(res.data.raw[i].session);
                        session.sort((a, b) => a - b);
                    }
                }
                for (let i = 0; i < session.length; i += 1) {
                    const nzhcn = Nzh.hk;
                    let number = `${session[i]} Session`;
                    if (this.$route.path.split('/')[1] === 'zhTw') {
                        number = `第${nzhcn.encodeS(session[i])}屆`;
                    }
                    historyLecture.push({
                        number: session[i],
                        title: number,
                        items: res.data.raw.filter(
                            (value) => value.session === session[i],
                        ),
                    });
                    historyLecture.sort(this.asort_json_num('number'));
                    this.historyLecture = historyLecture;
                }
                this.$nextTick(() => {
                    this.initSwiper();
                });
            } catch (e) {
                this.handleError({
                    message: e || this.$t('alertMessage.getdata-fail'),
                    color: 'error',
                });
            }
            this.showData = true;
        },
        asort_json_num(property) {
            return (a, b) => b[property] - a[property];
        },
        goToSRIO() {
            this.$router.push({ name: 'StudySRIOItem' });
        },
        goToLink(item) {
            this.$router.push({ name: 'StudyHistoryItem', params: { id: item.id } });
        },
        image(item) {
            const file = `https://${process.env.VUE_APP_BASE_URL + item}`;
            return file;
        },
    },
};
</script>

<style lang="scss">
.study{
    color: $color-gray;

    .block-content{
        font-size: 16px;
        font-weight: 700;
        line-height: 2;
        text-align: justify;
        letter-spacing: 0.1em;
    }

    .list-group{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .list-item{
      display: flex;
      padding: 25px 0;
      cursor: pointer;
      //圖片暫時顏色
      .list-img{
        background-color: #d9d9d9;
      }
      .list-content{
          line-height: 2;
          letter-spacing: 0.1em;
          margin-left: 25px;
          .list-title{
              font-size: 21px;
              font-weight: 700;
              text-align: justify;
              color: $color-blue;
              overflow:hidden;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
          }
          .list-caption{
              font-size: 16px;
              font-weight: 700;
              text-align: justify;
              overflow:hidden;
              display:-webkit-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
          }
      }
    }
  }
}

.card-group {
    margin: 0;
}

.history-title{
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
    letter-spacing: 0.1em;
    color: $color-blue;
    text-align: center;
}

.history-content{
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 280px);
    grid-gap: 0px;

    .history-item{
        width: 280px;
        margin-top: 20px;
        cursor: pointer;
        .history-title{
            font-weight: 700;
            font-size: 21px;
            line-height: 2;
            letter-spacing: 0.1em;
            color: $color-blue;
            text-align: center;
        }
    }
}

.swiper-history{
  display: none;
}

.v-expansion-panel-header__icon {
    position: absolute;
    right: 0;
    .v-icon {
        color: $color-orange !important;
    }
}

@media screen and (max-width: 1280px) {
  .history-content{
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 960px) {
  .study{
    .list-group{
      align-items: center;
      .list-item{
        max-width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .list-content{
            margin-left: 0;
        }
      }
    }
    .swiper-history{
      display: flex;
      .swiper-slide{
        display: flex;
        justify-content: center;
        .content{
          width: 280px;
          cursor: pointer;
          .swiper-img{
            width: 280px;
            height: 280px;
          }
          .history-title{
            font-weight: 700;
            font-size: 21px;
            line-height: 2;
            letter-spacing: 0.1em;
            color: $color-blue;
            text-align: center;
          }
        }
      }
    }
    .invention-swiper-button-next,
    .invention-swiper-button-prev {
      --swiper-theme-color: #f97e2b;
    }
  }
  .history-content{
    display: none;
  }
  .study .invention-swiper-button-next, .study .invention-swiper-button-prev {
    width: 0px;
    height: 0px;
  }
}

@media screen and (max-width: 450px) {
  .study{
    .swiper{
      .swiper-slide{
        .content{
          width: 200px;
          .swiper-img{
            width: 200px;
            height: 200px;
          }
        }
      }
    }
    .invention-swiper-button-next,
    .invention-swiper-button-prev {
      --swiper-theme-color: #f97e2b;
    }
  }
}
</style>
